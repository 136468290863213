.Features {
  min-height: 100vh;
  background-color: #242934;
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 950px) {
    flex-direction: column;

    & > div {
      height: 100vh;
      min-height: 100vh !important;
    }
  }

  .features-illustration {
    flex: 1;
    background-image: url('../../img/rail_mountain.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding-left: 10%;
    padding-right: 35px;
    //min-width: 420px;
    min-height: 800px;

    .illustration-title {
      max-width: 400px;
      margin-top: 12vh;
    }
  }

  .features-list {
    flex: 1.5;
    padding-left: 10%;
    margin-bottom: 80px;
    min-width: 600px;

    @media screen and (max-width: 750px) {
      min-width: auto;
    }

    h1 {
      margin-top: 12vh;
      max-width: 640px;
      color: white;
    }

    .feature-icons {
      flex: 1;
      flex-wrap: wrap;
      display: flex;
      max-width: 720px;
      margin-bottom: 50px;
    }
  }

  .feature-text {
    color: #c9ceda;
    max-width: 680px;
    padding-right: 10vw;

    @media screen and (max-width: 1100px) {
      max-width: 670px;
    }
  }
}

.FeatureIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #1b1f27;
  border-radius: 10px;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.12);
  height: 170px;
  width: 170px;
  padding: 15px;
  margin-bottom: 30px;
  margin-right: 30px;
  transition: all ease-out 0.2s;

  .icon {
    height: 40%;
    width: 60%;

    > * {
      width: 100%;
      height: 100%;
    }
  }

  p {
    text-align: center;
    margin: 0;
    margin-top: 25px;
    line-height: 110%;
    color: #c9ceda;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  }

  @media screen and (max-width: 750px) {
    width: 70vw;
  }
}
