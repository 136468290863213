.Intro {
  min-height: 100vh;
  display: flex;

  .text-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 80px; // header
    padding-left: 10%;
    padding-right: 60px;

    h1 {
      margin-top: 20%;
      max-width: 560px;
    }

    p {
      max-width: 560px;
    }

    .gestio-modules-logos {
      margin-top: 30px;
      display: flex;

      .module-logo {
        flex: 1;
        background-position: center;
        background-repeat: no-repeat;
        height: 42px;
        max-width: 130px;
        display: inline-block;
        margin-right: 35px;
      }

      .logo-rail {
        background-image: url('../../img/gestio_rail_logo_full-black.svg');
      }

      .logo-route {
        background-image: url('../../img/gestio_route_logo_full-black.svg');
      }
    }

    .intro-footer {
      flex: 1;
      display: flex;
      align-items: flex-end;

      .scroll-down-indicator {
        display: flex;
        align-items: center;

        margin-bottom: 80px;
        margin-top: 30px;

        &:hover {
          cursor: pointer;
          .scroll-down-icon {
            box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15);
          }
        }

        .scroll-down-icon {
          height: 50px;
          width: 50px;
          border-radius: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #242934;
          margin-right: 25px;
          transition: box-shadow 0.2s ease;
        }
      }
    }
  }

  .hero-picture-composition {
    flex: 1.5;
    position: relative;

    .logo-overlay {
      position: absolute;
      right: 0;
      height: 100%;
      width: 101%;
      margin-right: -1px;
      background-image: url('../../img/gestio_logo_white.svg');
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 10;
    }

    .hero-picture {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    @media screen and (max-width: 950px) {
      display: none;
    }
  }

  .intro-slides {
    margin-top: 80px;
    margin-bottom: 80px;

    .slideshow-launcher {
      font-size: 1.9rem;
    }
  }
}
