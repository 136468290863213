.News {
  min-height: 100vh;
  background-color: #242934;
  display: flex;
  flex-wrap: no-wrap;
  width: 100vw;

  @media screen and (max-width: 950px) {
    flex-direction: column;

    & > div {
      height: 100vh;
      min-height: 100vh !important;
    }
  }

  .news-illustration {
    flex: 1;
    background-image: url('../../img/chaumont.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding-left: 10%;
    padding-right: 35px;
    //min-width: 420px;
    min-height: 800px;

    .illustration-title {
      max-width: 400px;
      margin-top: 12vh;
    }
  }

  .news-list-container {
    flex-grow: 3;
    flex-basis: 1px;
    position: relative;

    .news-list {
      position: absolute; /* make sure news-list size is children-size agnostic */
      inset: 0 0 0 0;

      padding-left: 10%;
      padding-right: 10%;
      margin-bottom: 80px;

      @media screen and (max-width: 950px) {
        padding: 0;
        width: 100%;

        .carousel-container {
          width: 100%;
        }
      }

      h1 {
        margin-top: 12vh;
        max-width: 640px;
        color: white;

        @media screen and (max-width: 950px) {
          padding-left: 10%;
        }
      }

      .news-icons {
        flex: 1;
        flex-wrap: wrap;
        display: flex;
        max-width: 720px;
        margin-bottom: 50px;
      }
    }

    .feature-text {
      color: #c9ceda;
      max-width: 680px;
      padding-right: 10vw;

      @media screen and (max-width: 1100px) {
        max-width: 670px;
      }
    }
  }
}

.NewsComp {
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #1b1f27;
  border-radius: 10px;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.12);
  min-height: 500px;
  width: 100%;
  padding: 15px;
  margin-bottom: 30px;
  margin-right: 30px;
  transition: all ease-out 0.2s;

  text-align: center;
  margin-top: 25px;
  line-height: 110%;
  color: #c9ceda;

  @media screen and (max-width: 950px) {
    width: 100vw;
  }
}
