.Footer {
  height: 450px;
  background-color: #242934;
  display: flex;

  @media screen and (max-width: 950px) {
    flex-direction: column;
    height: auto;
  }

  .Footer__logos {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 950px) {
      display: none;
    }

    .gestio-logo {
      background-image: url('../../img/gestio_logo_full--white.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 200px;
      height: 70px;
    }

    .opan-logo {
      background-image: url('../../img/opan_logo_full.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 200px;
      height: 70px;
    }

    p {
      color: white;
    }
  }

  .Footer__contact {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;

    a {
      color: white;
      text-decoration: none;
    }

    @media screen and (max-width: 950px) {
      padding: 30px;
      p {
        text-align: center;
      }
    }
  }

  .Footer__madeIn {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    color: white;
    margin-right: 30px;

    @media screen and (max-width: 950px) {
      align-items: center;
      justify-content: center;
      margin: 0;
    }

    .made-in__wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 25px;

      @media screen and (max-width: 950px) {
        flex-direction: column;

        * {
          text-align: center;
        }

        .made-in-ch-logo {
          margin: 0 0 20px 0 !important;
        }
      }

      .made-in-ch-logo {
        height: 40px;
        width: 40px;
        background-color: #e44233;
        border-radius: 30px;
        margin-right: 20px;
        display: flex;

        span {
          font-size: 2rem;
          font-weight: 700;
        }

        > * {
          margin: auto;
        }
      }

      p {
        margin: 0;
      }

      .heart {
        position: relative;
        animation: beat2 linear 1s infinite;

        .heartbeat-effect {
          position: absolute;
          position: absolute;
          top: 50%;
          left: 50%;

          transform: translate(-50%, -50%);
          background-color: #e44233;
          width: 80px;
          height: 80px;
          border-radius: 40px;
          animation: beat linear 1.2s infinite;
        }
      }

      @keyframes beat {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) scale(0);
        }
        25% {
          opacity: 0.2;
          transform: translate(-50%, -50%) scale(0.5);
        }
        75% {
          opacity: 0;
          transform: translate(-50%, -50%) scale(1);
        }
        100% {
          opacity: 0;
          transform: translate(-50%, -50%) scale(1);
        }
      }

      @keyframes beat2 {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.2);
        }
        100% {
          transform: scale(1);
        }
      }
    }

    .copyright {
      opacity: 0.5;
    }
  }
}
