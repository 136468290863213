.Header {
  top: 0;
  position: fixed;
  width: 100vw;
  height: 110px;
  display: flex;
  padding: 10px 10vw;
  align-items: center;
  z-index: 1000;
  transition: height ease 0.1s;

  &.hasScrolled {
    backdrop-filter: blur(40px) saturate(130%);
    background-color: rgba(255, 255, 255, 0.25);
    height: 50px;

    @-moz-document url-prefix() {
      background-color: rgba(255, 255, 255, 0.8);
    }

    .Header__logo {
      width: 180px;
    }

    @media screen and (max-height: 500px) {
      height: 0px;
      padding: 0;
    }
  }

  .Header__logo {
    background-image: url('../../img/gestio_logo_full.svg');
    height: 60%;
    width: 300px;
    background-repeat: no-repeat;
    background-position: center;
  }

  @media screen and (max-width: 950px) {
    height: 50px;
    .Header__logo {
      width: 180px;
    }
  }
}
