body {
  margin: 0;
  font-family: 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #242934;
  --orange: #f5a234;
}

img {
  border: 0;
}

// === Typography

// Fonts

/* poppins-600 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('../src/fonts/poppins-v13-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
    url('../src/fonts/poppins-v13-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../src/fonts/poppins-v13-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../src/fonts/poppins-v13-latin-600.woff') format('woff'),
    /* Modern Browsers */ url('../src/fonts/poppins-v13-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../src/fonts/poppins-v13-latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-700 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('../src/fonts/poppins-v13-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Poppins Bold'), local('Poppins-Bold'),
    url('../src/fonts/poppins-v13-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../src/fonts/poppins-v13-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../src/fonts/poppins-v13-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('../src/fonts/poppins-v13-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../src/fonts/poppins-v13-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* work-sans-regular - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../src/fonts/work-sans-v8-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../src/fonts/work-sans-v8-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../src/fonts/work-sans-v8-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../src/fonts/work-sans-v8-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../src/fonts/work-sans-v8-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../src/fonts/work-sans-v8-latin-regular.svg#WorkSans')
      format('svg'); /* Legacy iOS */
}
/* work-sans-600 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../src/fonts/work-sans-v8-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''), url('../src/fonts/work-sans-v8-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../src/fonts/work-sans-v8-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../src/fonts/work-sans-v8-latin-600.woff') format('woff'),
    /* Modern Browsers */ url('../src/fonts/work-sans-v8-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../src/fonts/work-sans-v8-latin-600.svg#WorkSans') format('svg'); /* Legacy iOS */
}

// Classes

h1 {
  font-size: 2.6rem;
  font-family: 'Poppins';
  font-weight: 700;
  margin-bottom: 80px;
  line-height: 120%;
  padding-right: 20px;

  .lighter {
    opacity: 0.5;
  }

  @media screen and (max-width: 950px) {
    font-size: 1.8rem;
  }
}

h2 {
  font-family: 'Poppins';
  font-size: 1.9rem;
  font-weight: 600;
  line-height: 130%;

  .lighter {
    opacity: 0.5;
  }

  &::before {
    content: '';
    display: block;
    margin-top: -10px;
    margin-bottom: 12px;
    height: 5px;
    width: 25px;
    background-color: #242934;
  }

  &::after {
    content: '';
    display: block;
    margin-bottom: 10px;
    margin-top: 12px;
    height: 5px;
    width: 25px;
    background-color: #242934;
  }

  @media screen and (max-width: 950px) {
    font-size: 1.4rem;
  }
}

h3 {
  font-family: 'Poppins';
  font-size: 1.3rem;
}

p {
  text-align: justify;
  line-height: 160%;
  margin-top: 0;

  @media screen and (max-width: 950px) {
    font-size: 0.9rem;
  }
}
