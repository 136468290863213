.Videos {
  min-height: 100vh;
  display: flex;
  background-color: #f2f2f2;

  @media screen and (max-width: 950px) {
    flex-direction: column-reverse;

    & > div {
      height: 100vh;
      min-height: 100vh !important;
    }
  }

  .videos-content {
    flex: 1.5;
    .video-container {
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 950px) {
        margin-top: 25vh;
      }
    }
  }

  .videos-illustration {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-image: url('../../img/arches.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .videos-illustration__citation {
      padding: 0 60px 0 90px;
      padding-top: 10vh;
      margin-bottom: 10vh;
      flex: 1;
      background: transparent;
      background: linear-gradient(180deg, rgba(20, 30, 2, 0.75) 0%, rgba(0, 0, 0, 0.1) 50%);
    }

    h2 {
      color: white;
      &::before,
      &::after {
        background-color: white;
      }
    }
  }

  .videos-icons {
    display: flex;
    flex-wrap: wrap;
    max-width: 700px;
    margin-bottom: 80px;
  }
}
