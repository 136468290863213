/*
    Created on : May 3, 2023, 9:22:46 PM
    Author     : laurent@netconcept.local
*/

.News .news-list .oft-card {
  width: 100%;
  height: 100%;
  min-height: 500px;
  background-color: white;

  background-image: url('../../img/oft.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  h1 {
    margin-top: 20px;
    color: #f5a234;
    margin-left: auto;
    margin-right: 20px;
  }

  .oft-card__launcher {
    position: absolute;
    right: 15px;
    width: max-content;
    padding: 10px 20px;
    background-color: rgba(255, 255, 255, 0.8);
    color: #f5a234;
  }

  .oft-card__title {
    font-size: 1.9rem;
    line-height: 130%;
    font-family: 'Poppins';
    color: #f5a234;
    padding: 20px;
    // text-shadow: 1px 1px 0px white;
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 15px;

    @media screen and (max-width: 950px) {
      font-size: 1rem;
    }
  }

  img {
    max-height: 15vh;
    margin-top: 40px;
  }
}
