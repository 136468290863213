/*
    Created on : May 3, 2023, 9:22:46 PM
    Author     : laurent@netconcept.local
*/

.News .news-list .mvr-card {
  width: 100%;
  height: 100%;
  background-color: white;

  h1 {
    color: #f5a234;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    max-height: 15vh;
    max-width: 90vw;
    margin-top: 40px;
  }
}
