/*
    Created on : May 2, 2023, 8:39:07 PM
    Author     : laurent@netconcept.local
*/

.modal-fullscreen {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1001;
  background-color: white;

  .modal__close-button {
    position: fixed;
    color: lightgrey;
    cursor: pointer;
    right: 15px;
    top: 15px;
    z-index: 1002;
    cursor: 'pointer';

    &:hover {
      color: grey;
    }
  }
}
