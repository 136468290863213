.Advantages {
  min-height: 100vh;
  display: flex;
  background-color: #f2f2f2;

  @media screen and (max-width: 950px) {
    flex-direction: column;
  }

  .advantages-text {
    flex: 1.5;
    padding-left: 10vw;

    h1 {
      margin-top: 12vh;
    }

    .advantages-paragraph {
      margin-bottom: 60px;
      max-width: 680px;

      @media screen and (max-width: 1160px) {
        padding-right: 10vw;
      }
    }
  }

  .advantages-illustration {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-image: url('../../img/railway_aerial.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .advantages-illustration__citation {
      padding: 0 60px 0 90px;
      padding-top: 10vh;
      margin-bottom: 10vh;
      flex: 1;
      background: transparent;
      background: linear-gradient(180deg, rgba(20, 30, 2, 0.75) 0%, rgba(0, 0, 0, 0.1) 50%);
    }

    h2 {
      color: white;
      &::before,
      &::after {
        background-color: white;
      }
    }
  }

  .advantages-icons {
    display: flex;
    flex-wrap: wrap;
    max-width: 700px;
    margin-bottom: 80px;
  }
}

.AdvantageIcon {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.12);
  transition: all ease-out 0.2s;
  max-width: 280px;
  height: 200px;
  padding: 15px;
  margin-bottom: 30px;
  margin-right: 30px;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  }

  h3 {
    margin-bottom: 5px;
    margin-top: 0;
  }

  p {
    text-align: left;
  }

  .AdvantageIcon__separator {
    display: block;
    height: 3px;
    width: 30px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 750px) {
    width: 70vw;
    max-width: 100%;
  }
}
