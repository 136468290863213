/*
    Created on : May 2, 2023, 8:39:07 PM
    Author     : laurent@netconcept.local
*/

.slideshow-launcher {
  display: flex;
  gap: 5px;
  align-items: center;
}

.slideshow-modal {
  width: 100vw;
  height: 100vh;
  //padding-left: 5%;
  //padding-right: 5%;
  //padding-bottom: 5%;
  box-sizing: border-box;
}

.slideshow {
  position: relative;
  height: 100%;
  width: 100%;

  .slideshow__toolbar {
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;

    .btn {
      cursor: pointer;
      // background-color: lightgrey;
      // border-radius: 50%;
      width: 24px;
      height: 24px;
      //padding: 2px 0px 0 2px;
      /*
            &:hover {
                background-color: grey;
            }*/
    }

    .slideshow__toolbar__pages {
      align-self: flex-start;
    }
  }
}
