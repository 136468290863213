.video-proj-content {
  width: 100%;
  height: 100%;

  .video-container {
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 950px) {
      margin-top: 25vh;
    }
  }

  .card__download {
    width: max-content;
    padding-left: 10px;
    display: flex;
    align-items: center;
    font-size: 0.7em;
    gap: 5px;
    color: #f5a234;
  }

  .card__title {
    font-size: 1.9rem;
    line-height: 130%;
    font-family: 'Poppins';
    color: #f5a234;
    padding: 20px;
    // text-shadow: 1px 1px 0px white;
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 15px;

    @media screen and (max-width: 950px) {
      font-size: 1rem;
    }
  }
}
